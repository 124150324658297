/** @jsx jsx */
import { jsx, Link } from "theme-ui"
import useSiteMetadata from "../hooks/use-site-metadata"

const Footer = () => {
  const { siteTitle } = useSiteMetadata()

  return (
    <footer
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `space-between`,
        mt: [6],
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
        flexDirection: [`column`, `column`, `row`],
        variant: `dividers.top`,
      }}
    >
      <div>
        &copy; {new Date().getFullYear()} by {siteTitle}. All rights reserved.
      </div>
      <div>
        <Link
          aria-label="LinkedIn Profile"
          href="https://www.linkedin.com/in/nishantwrp"
        >
          LinkedIn
        </Link>
        {` | `}
        <Link
          aria-label="GitHub Profile"
          href="https://www.github.com/nishantwrp"
        >
        GitHub
        </Link>
        {` | `}
        <Link
          aria-label="Twitter Profile"
          href="https://www.twitter.com/nishantwrp"
        >
          Twitter
        </Link>
      </div>
    </footer>
  )
}

export default Footer
